import site from "../assets/img/site.png"
import admin from "../assets/img/admin.png"
import cgs from "../assets/img/cgs.png"

export const ProjectList = [
    {
        name: "CGS Express",
        image: cgs,
        skill: "Node.js, Express, Sequelize, Javascript, MySQL, Docker",
        description: "I took care of the backend part together with another developer, using Node.js and Sequelize as ORM. The private mail platform consisted of an admin area and a user area. User area: login, creation and printing of self-generated labels, to request the collection of a package, top up wallet via PayPal API. Admin area: account creation, user wallet top-up.\n" +
            "Additional GLS API implementation. Subsequent maintenance.",
        link: "https://web.whatsapp.com/"
    },
    {
        name: "Portfolio",
        image: site,
        skill: "React.js, CSS, Firebase",
        description: "It's my first real experience on the frontend except small maintenance phases during my previous jobs. I used React.js, using css to style the navbar and footer across the various pages and responsiveness of the whole project. I used icons from MUI. I deployed using Firebase from Google.",
        link: ""
    },
    {
        name: "Personal dashboard",
        image: admin,
        skill: "ASP.NET, EntityFramework, PostgreSQL, React.js, Firebase",
        description: "Still work in progress. From my portfolio site I cut an admin branch with a login form, to then access a dashboard where I will report all the activities to be done during the month, week or day, with the possibility of creating new areas as desired.Backend done with ASP.NET using EntityFramework.",
        link: ""
    },
]