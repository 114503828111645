import {Link, useLocation} from "react-router-dom";
import "../styles/Navbar.css"
import ReorderIcon from "@mui/icons-material/Reorder";
import {useEffect, useState} from "react";
import logo from "../assets/img/logo.jpg"

function Navbar() {
    const [expandNavbar, setExpandNavbar] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setExpandNavbar(false)
    }, [location]);

    return (<div className="navbar" id={expandNavbar ? "open" : "close"}>
        <div className="toggle-button">
            <button onClick={() => {
                setExpandNavbar((prev) => !prev)
            }}><ReorderIcon/></button>
        </div>
        <div className="links">
            <img src={logo} className="logo" alt="therelafiocco"/>
            <Link to="/" className="click">Home</Link>
            <Link to="/projects" className="click">Projects</Link>
            <Link to="/experience" className="click">Experience</Link>
        </div>
    </div>)
}

export default Navbar