import ProjectItem from "../components/ProjectItem";
import "../styles/Projects.css"
import {ProjectList} from "../helpers/ProjectList";
import loading from "../assets/gifs/loading.gif"

function Projects() {
    return (
        // <div className="projects">
        <div className="gif-container">
            <img src={loading} alt="loading..."/>
            {/*<h1>My personal projects</h1>*/}
            {/*<h4>Only project that i did in my free time or as freelancer or for unpaid work for friends.</h4>*/}
            {/*<div className="project-list">*/}
            {/*    {ProjectList.map((project, idx) => {*/}
            {/*        return <ProjectItem name={project.name} image={project.image} id={idx} skil={project.skill}/>*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    )
}

export default Projects