import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ProjectDisplay from "./pages/ProjectDisplay";
// import Private from "./pages/Private";
// import Dashboard from "./pages/Dashboard";

import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAs4w1QAzmy8NdM1_DM4oAHBNSw3_6wGIM",
    authDomain: "therealfiocco.firebaseapp.com",
    projectId: "therealfiocco",
    storageBucket: "therealfiocco.appspot.com",
    messagingSenderId: "505505265913",
    appId: "1:505505265913:web:927ecc526cb1da1bc1ca28",
    measurementId: "G-EN3X2DY0HN"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'notification_received');

function App() {
    return (
        <div className="App">
            <Router>
                {/*{window.location.pathname !== '/admin' && <Navbar/>}*/}
                <Navbar/>
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/projects' element={<Projects/>}/>
                    <Route path='/project/:id' element={<ProjectDisplay/>}/>
                    <Route path='/experience' element={<Experience/>}/>
                    {/*<Route path='/admin' element={<Private/>}/>*/}
                    {/*<Route path='/admin/dashboard' element={<Dashboard/>}/>*/}
                </Routes>
                {/*{window.location.pathname !== '/admin' && <Footer/>}*/}
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
