import {Email, GitHub, LinkedIn} from "@mui/icons-material";
import "../styles/Home.css"
import logo from "../assets/img/logo.jpg"
import IconList from "../components/IconList";

function Home() {
    return (
        <div className="home">
            <div className="about">
                <h2>Hi, my name is Antonio</h2>
                <img src={logo} alt=""/>
                <div className="prompt">

                    <p className="description">Backend developer aspiring to be a fullstack.</p>
                    <LinkedIn className="icons" onClick={() => {
                        window.open("https://www.linkedin.com/in/fiocco-antonio", "_blank");
                    }}/>
                    <Email className="icons" onClick={() => {
                        window.open("mailto:antonio18fiocco@gmail.com", "_blank");
                    }}/>
                    <GitHub className="icons" onClick={() => {
                        window.open("https://github.com/therealfiiocco", "_blank");
                    }}/>
                </div>
            </div>
            {/*<div className="skills">*/}
            {/*    <h1 className="skillsName">Skills</h1>*/}
            {/*    <ol className="list">*/}
            {/*        <li className="item">*/}
            {/*            <h2>Back-end</h2>*/}
            {/*            <span>Node.js, .NET, Laravel, MySQL, PostgreSQL</span>*/}
            {/*        </li>*/}
            {/*        <li className="item">*/}
            {/*            <h2>Front-end</h2>*/}
            {/*            <span>Html, Css, React.js</span>*/}
            {/*        </li>*/}
            {/*    </ol>*/}
            {/*</div>*/}
            <h1 className="skillsName">Skills</h1>
            <IconList/>
        </div>
    )
}

export default Home