import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import {Work, WorkHistory} from "@mui/icons-material";
import "../styles/Experience.css"

function Experience() {
    return (
        <div className="experience">
            <h1><VerticalTimeline lineColor="#5b15fa">
                <VerticalTimelineElement className="vertical-timeline-element--work"
                                         date="2024 July - "
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<WorkHistory/>}>
                    <h4 className="vertical-timeline-element-title">IQUII</h4>
                    <p className="paragraph">• Consultant through ODDA. Porting an E-commerce site to Shopify, using
                        lambda functions. Writing, using javascript, a script that automatically handles data on a
                        Mongo-db database.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement className="vertical-timeline-element--work"
                                         date="2023 March - 2024 July"
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<Work/>}>
                    <h4 className="vertical-timeline-element-title">Fastweb</h4>
                    <p className="paragraph">• Consultant through ODDA. Vision of cloud server dynamics. I used AWS
                        APPSync, AWS QuickSight,
                        AWS Cloudwatch, AWS Lambda and first frontend steps not only in the personal field.React and
                        Javascript for a project develop.React and express.js for developing and fixing some features in
                        a project.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement className="vertical-timeline-element--work"
                                         date="2022 September - "
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<WorkHistory/>}>
                    <h4 className="vertical-timeline-element-title">ODDA Studio</h4>
                    <p className="paragraph">• Several projects using .NET or Node.js through Nest.js or typescript.It's
                        definitely the experience where I'm learning the most. I studied docker in depth, I developed
                        disparate products with particular features for example an uploader and downloader and another
                        project for backup.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement className="vertical-timeline-element--work"
                                         date="2021 November - 2022 August"
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<Work/>}>
                    <h4 className="vertical-timeline-element-title">Microdata</h4>
                    <p className="paragraph">• Low level programming, for infrared burglar alarm. I used C and learned a
                        lot, even though it wasn't exactly my field. Very first frontend views through small fixes
                        and additions in ASP.NET MVC. I then also started looking at C# to use ASP.NET.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement className="vertical-timeline-element--work" date="2021 March - 2021 September"
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<Work/>}>
                    <h4 className="vertical-timeline-element-title">Idara</h4>
                    <p className="paragraph">• In this experience I took care of the backend, this time using Php and
                        Laravel. After the first real experience, created in Node.js, I realized the differences and
                        what I preferred to delve into based on my preferences.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement className="vertical-timeline-element--work"
                                         date="2020 September - 2021 October"
                                         dateClassName="date"
                                         iconStyle={{background: "#5b15fa", color: "#fff"}}
                                         icon={<Work/>}>
                    <h4 className="vertical-timeline-element-title">CGS</h4>
                    <p className="paragraph">• Backend for an order management software for a private company using
                        Node.js. First real experience working in a team. Here I learned much of the back-end world and
                        the Agile methodology. Partly also viewed the implementations of external APIs.</p>
                </VerticalTimelineElement>
            </VerticalTimeline></h1>
        </div>
    )
}

export default Experience