import {FacebookOutlined, Instagram, LinkedIn} from "@mui/icons-material";
import "../styles/Footer.css"
import Icon from '@mdi/react';
import {mdiSteam} from '@mdi/js';


function Footer() {
    return (
        <div className="footer">
            <div className="social-media">
                <Instagram className="icons" onClick={() => {
                    window.open("https://www.instagram.com/therealfiiocco/", "_blank");
                }}/>
                <FacebookOutlined className="icons" onClick={() => {
                    window.open("https://www.facebook.com/therealfiocco/", "_blank");
                }}/>
                {/*Inserire Steam al posto di twitter*/}
                <LinkedIn className="icons"
                          onClick={() => {
                              window.open("https://www.linkedin.com/in/fiocco-antonio/", "_blank");
                          }}/>
                <Icon path={mdiSteam} className="steam icons"
                      onClick={() => {
                          window.open("https://steamcommunity.com/id/therealfiocco/", "_blank")
                      }}
                />
            </div>
            <p>&copy; 2024 therealfiocco.it</p>
        </div>
    )
}

export default Footer