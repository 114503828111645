import {Link, useParams} from "react-router-dom";
import {ProjectList} from "../helpers/ProjectList";
import {GitHub} from "@mui/icons-material";
import "../styles/ProjectDisplay.css"

function ProjectDisplay() {
    const {id} = useParams();
    const project = ProjectList[id]
    return (
        <div className="project">
            <h1 className="h1">{project.name}</h1>
            <img src={project.image} alt=""/>
            <p>Skills: <br/>{project.skill}</p>
            <p className="activity">{project.description}</p>
            <Link to={project.link}><GitHub/></Link>
        </div>
    )
}

export default ProjectDisplay