import React from 'react';
import c from "../assets/skills-icons/c.png";
import css from "../assets/skills-icons/css.png";
import docker from "../assets/skills-icons/docker.png";
import express from "../assets/skills-icons/express.png";
import firebase from "../assets/skills-icons/firebase.png";
import html from "../assets/skills-icons/html.png";
import js from "../assets/skills-icons/js.png";
import laravel from "../assets/skills-icons/laravel.png";
import nestjs from "../assets/skills-icons/nestjs.png";
import net from "../assets/skills-icons/net.png";
import nodejs from "../assets/skills-icons/node-js.svg";
import php from "../assets/skills-icons/php.png";
import postgresql from "../assets/skills-icons/postgresql.png";
import react from "../assets/skills-icons/react.png";
import sequelize from "../assets/skills-icons/sequelize.png";
import sql from "../assets/skills-icons/mysql.png";
import ts from "../assets/skills-icons/ts.png";
import typeorm from "../assets/skills-icons/typeorm.svg";

const IconList = () => {

    return (
        <div className="icons-area">
            {/*{IconsSkill.map((img, index) => {*/}
            {/*    return <img key={index} src={img.url} alt={img.alt}/>;*/}
            {/*})}*/}
            <div onClick={() => window.open("https://devdocs.io/c/", "_blank")}><img src={c} alt="c"/>
                <p className="label-icons">C</p>
            </div>
            <div onClick={() => window.open("https://nodejs.org/docs/latest/api/", "_blank")}><img src={nodejs}
                                                                                                   alt="nodejs"
            />
                <p className="label-icons">NodeJS</p>
            </div>
            <div onClick={() => window.open("https://developer.mozilla.org/en-US/docs/Web/JavaScript", "_blank")}><img
                src={js} alt="js"
            />
                <p className="label-icons">Javascript</p></div>
            <div onClick={() => window.open("https://www.typescriptlang.org/docs/", "_blank")}><img src={ts} alt="ts"/>
                <p className="label-icons">Typescript</p></div>
            <div><img src={nestjs} alt="nestjs" onClick={() => window.open("https://docs.nestjs.com/", "_blank")}/>
                <p className="label-icons">NestJS</p></div>
            <div onClick={() => window.open("https://expressjs.com/", "_blank")}><img src={express} alt="express"/>
                <p className="label-icons">Express</p>
            </div>
            <div onClick={() => window.open("https://sequelize.org/", "_blank")}><img src={sequelize} alt="sequelize"
            />
                <p className="label-icons">Sequelize</p></div>
            <div onClick={() => window.open("https://typeorm.io/", "_blank")}><img src={typeorm} alt="typeorm"/>
                <p className="label-icons">TypeORM</p>
            </div>
            <div onClick={() => window.open("https://dev.mysql.com/doc/", "_blank")}><img src={sql} alt="sql"/>
                <p className="label-icons">MySQL</p></div>
            <div onClick={() => window.open("https://www.postgresql.org/docs/", "_blank")}><img src={postgresql}
                                                                                                alt="postgresql"
            />
                <p className="label-icons">PostgreSQL</p></div>
            <div onClick={() => window.open("https://www.php.net/docs.php", "_blank")}><img src={php} alt="php"/>
                <p className="label-icons">PHP</p>
            </div>
            <div onClick={() => window.open("https://laravel.com/docs/11.x", "_blank")}><img src={laravel} alt="laravel"
            />
                <p className="label-icons">Laravel</p></div>
            <div onClick={() => window.open("https://learn.microsoft.com/it-it/dotnet/", "_blank")}><img src={net}
                                                                                                         alt="net"
            />
                <p className="label-icons">.NET</p></div>
            <div onClick={() => window.open("https://docs.docker.com/", "_blank")}><img src={docker} alt="docker"/>
                <p className="label-icons">Docker</p>
            </div>
            <div onClick={() => window.open("https://firebase.google.com/docs?hl=it", "_blank")}><img src={firebase}
                                                                                                      alt="firebase"
            />
                <p className="label-icons">Firebase</p></div>
            <div onClick={() => window.open("https://developer.mozilla.org/en-US/docs/Web/HTML", "_blank")}><img
                src={html} alt="html"
            />
                <p className="label-icons">HTML</p></div>
            <div onClick={() => window.open("https://developer.mozilla.org/en-US/docs/Web/CSS", "_blank")}><img
                src={css} alt="css"
            />
                <p className="label-icons">CSS</p></div>
            <div onClick={() => window.open("https://legacy.reactjs.org/docs/getting-started.html", "_blank")}><img
                src={react} alt="react"
            />
                <p className="label-icons">ReactJS</p></div>
        </div>
    );
};

export default IconList;
